
import { defineComponent, ref, onMounted, watch, Ref } from 'vue';
import { useRoute } from 'vue-router';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { campHandleCNPJ, campHandleBrPhoneNumber, campHandleEmailVerification, campHandleEmptyFields, campHandleEveryoneIsTrue } from '@/composables/DataValidation';
import { handleAnonymizeName } from '@/composables/DataAnonymization';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
  CampTableTh,
  CampTableTd,
  CampTable,
  CampEmptyListFeedbackV2,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import  ModalCropImage from './Components/ModalCropImage.vue'
import { Modal } from "bootstrap";
import { ApiGet } from "@/services/ApiService"
import SkeletonComponent from '@/components/SkeletonComponent.vue';
import { TDaysOfWeek, daysOfWeek } from './PostPage.vue';

interface ICompanyData {
  id: number,
  cnpj: string,
  corporate_name: string,
  fantasy_name: string,
  responsible: string,
  email: string,
  telephone: string,
  type: string,
  deleted_at: string | null,
  min_points_rescue: number,
  moderator_points: number,
  back_card_image: string,
  front_card_image: string,
  remittance_email: string,
  weekday: TDaysOfWeek,
  days_to_expiry?: null | number,
  days_to_expiry_stock?: null | number,
}

interface ICompanyResponse {
  data: {
    error: boolean,
    message: string,
    data: ICompanyData
    errorCode: string
  },
  status: number
}

interface IUserSelect {
  id: number;
  name: string  
}

export default defineComponent({
  name: "CompanyPut",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    ModalCropImage,
    CampTableTh,
    CampTableTd,
    CampTable,
    SkeletonComponent,
    CampEmptyListFeedbackV2
},
  setup() {
    const loaderStore = useLoaderStore();
    const route = useRoute();

    const hasVmodelChanged = ref(false)
    const queryObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | ICompanyData
    }>({
      isLoading: false,
      noError: false,
      data: null
    })
    const modelDaysToExpiry = ref({
      checked: false,
      amountBackup: 30
    })
    const modelDaysToExpiryStock = ref({
      checked: false,
      amountBackup: 30
    })

    const imgCard = ref({
      imgBack:"" as any,
      imgNameLogo: "",
      imgLogo: "" as any,
      imgNameBack: ""
    });
    const selectUserCompany = ref()
    const userList: Ref<never[] | IUserSelect[]> = ref([])
    const userCompanyList = ref()
    const { showTimeConfirmDelete } = useAlert()

    const proportion = ref()
    const modatlCroppedLogo = ref(true)

    const auxModal:any = ref(null);
    const openModal = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModal.value = new Modal(auxElement);
          auxModal.value.show();
        };
    const isLoading = ref(false)

    async function getUser() {
        const response = await ApiGet("/api/getUser?getAll=true")
        let users = response?.data.data 
        users.forEach(elem => {
          if(!userCompanyList.value.some(element => element.id == elem.id)) {
            userList.value = [...userList.value, elem]
          }
        })

    }

    async function getUserView(userId) {
      isLoading.value = true
        const response = await ApiGet(`/api/getUserView/${userId}`)
        selectUserCompany.value = ''
        const user = response?.data.data
        userCompanyList.value = [...userCompanyList.value , {...user, cut: false}]
        filterSelectUser(userId)
        isLoading.value = false
    }

    function filterSelectUser(id) {
      userList.value = userList.value.filter(elem => elem.id != id)
    }

    async function getUserCompany(companyId) {
      isLoading.value = true
      const response = await ApiGet(`/api/getUserCompany?id_company=${companyId}`)
      let newUser = response?.data.data.map(elem => {
        return {
          ...elem,
          cut:false
        }
      })
      userCompanyList.value = newUser
      isLoading.value = false
    }

    const auxModalBack:any = ref(null);
    const openModalBack = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModalBack.value = new Modal(auxElement);
          auxModalBack.value.show();
        };

    function setImageCroped(value) {
      if(!modatlCroppedLogo.value) {
        imgCard.value.imgBack = value.croppedImgBase64
        openModalBack("staticBackdropBack")
      }
      if(modatlCroppedLogo.value) {
        imgCard.value.imgLogo = value.croppedImgBase64
        openModal("staticBackdrop")
      }
    }

    
    function handleDeleteUserCompany(index, name) {
      showTimeConfirmDelete('', "Este usuário será deletado após salvar alterações, deseja continuar?" , "Sim", `${name} Deletado com sucesso!`,  () => userCompanyList.value[index].cut = true, 'warning', true)
    }

    function getImgLogo(event) {
              modatlCroppedLogo.value = true
              proportion.value = 10/10
              const fileInput = event.target as HTMLInputElement;
              const file = fileInput.files?.[0];
              if (file) {
                imgCard.value.imgLogo = file
                imgCard.value.imgNameLogo = file.name
              }
              fileInput.value = "";
            }

    function getImgBack(event) {
      modatlCroppedLogo.value = false
      proportion.value = 10/6
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0];
      if (file) {
        imgCard.value.imgBack = file
        imgCard.value.imgNameBack= file.name
      }
      fileInput.value = "";
    }


    function getImageCompany(companyImgLogo, companyImgBack, companyImgLogoName, companyImgBackName) {
      const splitString = (url) => {
        if(url) {
          const parts = url.split('/');
          return parts[parts.length - 1];
        }
      }
      imgCard.value.imgLogo = companyImgLogo
      imgCard.value.imgNameLogo = splitString(companyImgLogoName)
      imgCard.value.imgBack = companyImgBack
      imgCard.value.imgNameBack = splitString(companyImgBackName)
    }

    async function handleCompanyView() {
      loaderStore.open()

      try {
        const result: ICompanyResponse = await axios.get(`/api/getCompanyView/${id}`)
        const { data } = result
        queryObject.value.data = data.data
        if(typeof queryObject.value.data.days_to_expiry === "number" && queryObject.value.data.days_to_expiry > 0) {
          modelDaysToExpiry.value.checked = true
          modelDaysToExpiry.value.amountBackup = queryObject.value.data.days_to_expiry
          queryObject.value.data.days_to_expiry = null
        }
        if(typeof queryObject.value.data.days_to_expiry_stock === "number" && queryObject.value.data.days_to_expiry_stock > 0) {
          modelDaysToExpiryStock.value.checked = true
          modelDaysToExpiryStock.value.amountBackup = queryObject.value.data.days_to_expiry_stock
          queryObject.value.data.days_to_expiry_stock = null
        }
        await getUserCompany(data.data.id)
        getUser()
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
        if(queryObject.value.data) {
          getImageCompany(queryObject.value.data.front_card_image, queryObject.value.data.back_card_image, queryObject.value.data.front_card_image, queryObject.value.data.back_card_image)
        }
        hasVmodelChanged.value = false
      }
    }

    const { id } = route.params;
    const { showTimeAlert } = useAlert()

    /** Warning of unfilled fields */
    const isRequiredField = ref(false)
    const isInvalidFieldWarning = ref(false)
    const isInvalidField = ref<{ 
      cnpj: boolean,
      mail: boolean,
      remittanceEmail: boolean,
      phoneNumber: boolean,
      minPointsRescue: boolean,
      moderator_points: boolean,
      days_to_expiry: boolean,
      days_to_expiry_stock: boolean,
     }>({
      cnpj: false,
      mail: false,
      remittanceEmail: false,
      phoneNumber: false,
      minPointsRescue: false,
      moderator_points: false,
      days_to_expiry: false,
      days_to_expiry_stock: false,
    })
    const varChange = ref(false)

    watch(() => varChange.value, () => {
      router.push('/companhias')
    })
    function comeBackFunction() {
      if(hasVmodelChanged.value) {
        showTimeConfirmDelete(
          "Atualizações feitas não serão salvas, ","deseja continuar?", "Sim", "",
          () => varChange.value = !varChange.value,
          'warning', false
        )
      } else {
        router.push('/companhias')
      }
    }

    /** Validate CNPJ */
    watch(() => queryObject.value.data?.cnpj, () => {
      if(queryObject.value.data?.cnpj) {
        isInvalidField.value.cnpj = !campHandleCNPJ(queryObject.value.data.cnpj)
      }
    })

    /** Validate email */
    watch(() => queryObject.value.data?.email, () => {
      if(queryObject.value.data?.email) {
        isInvalidField.value.mail = !campHandleEmailVerification(queryObject.value.data.email)
      }
    })

    /** Validate remittance email */
    watch(() => queryObject.value.data?.remittance_email, () => {
      if(queryObject.value.data?.remittance_email?.length) {
        const emailList = queryObject.value.data.remittance_email.split(", ")
        emailList.some(email => isInvalidField.value.remittanceEmail = !campHandleEmailVerification(email))
      }
      else {
        isInvalidField.value.remittanceEmail = false
        queryObject.value.data!.weekday = ''
      }
    })

    /** Validate phone number */
    watch(() => queryObject.value.data?.telephone, () => {
      if(queryObject.value.data?.telephone) {
        isInvalidField.value.phoneNumber = !campHandleBrPhoneNumber(queryObject.value.data.telephone)
      }
    })

    /** Validate min points rescue */
    watch(() => queryObject.value.data?.min_points_rescue, () => {
      if(!(!!queryObject.value.data?.min_points_rescue))
        isInvalidField.value.minPointsRescue = true
      if(queryObject.value.data?.min_points_rescue)
        isInvalidField.value.minPointsRescue = (+queryObject.value.data.min_points_rescue < 1) ? true : false
    })

    /** Validate moderator points */
    watch(() => queryObject.value.data?.moderator_points, () => {
      if(typeof queryObject.value.data?.moderator_points === "string")
        isInvalidField.value.moderator_points = true
      if(queryObject.value.data?.moderator_points)
        isInvalidField.value.moderator_points = (+queryObject.value.data.moderator_points < 0) ? true : false
    })

    /** Validate min days to expiry */
    watch(() => queryObject.value.data?.days_to_expiry, () => {
      if(!modelDaysToExpiry.value.checked)
        return
      if(!(!!queryObject.value.data?.days_to_expiry))
        isInvalidField.value.days_to_expiry = true
      if(queryObject.value.data?.days_to_expiry)
        isInvalidField.value.days_to_expiry = (+queryObject.value.data.days_to_expiry < 1) ? true : false
    })

    watch(() => modelDaysToExpiry.value.checked, () => {
      if(!queryObject.value.data)
        return
      if(!queryObject.value.data.days_to_expiry) {
        queryObject.value.data.days_to_expiry = +modelDaysToExpiry.value.amountBackup
      } else {
        modelDaysToExpiry.value.amountBackup = +queryObject.value.data.days_to_expiry
        queryObject.value.data.days_to_expiry = null
      }
    })
    watch(() => queryObject.value.data?.days_to_expiry_stock, () => {
      if(!modelDaysToExpiryStock.value.checked)
        return
      if(!(!!queryObject.value.data?.days_to_expiry_stock))
        isInvalidField.value.days_to_expiry_stock = true
      if(queryObject.value.data?.days_to_expiry_stock)
        isInvalidField.value.days_to_expiry_stock = (+queryObject.value.data.days_to_expiry_stock < 1) ? true : false
    })

    watch(() => modelDaysToExpiryStock.value.checked, () => {
      if(!queryObject.value.data)
        return
      if(!queryObject.value.data.days_to_expiry_stock) {
        queryObject.value.data.days_to_expiry_stock = +modelDaysToExpiryStock.value.amountBackup
      } else {
        modelDaysToExpiryStock.value.amountBackup = +queryObject.value.data.days_to_expiry_stock
        queryObject.value.data.days_to_expiry_stock = null
      }
    })

    /** Clear warning | V-model global state change */
    watch(() => queryObject.value, () => {
      isRequiredField.value = false
      isInvalidFieldWarning.value = false
      hasVmodelChanged.value = true
    }, { deep: true })

    async function onSubmitForm() {
      isInvalidFieldWarning.value = false
      isRequiredField.value = false
      const { data } = queryObject.value
      if(!data) {
        return showTimeAlert('Algo deu errado tente novamente', "error")
      }
      // const { deleted_at, front_card_image, back_card_image, } = data
      const { id, cnpj, corporate_name, fantasy_name, responsible, email, telephone, type, min_points_rescue, moderator_points, days_to_expiry, days_to_expiry_stock } = data 
      const rest = {
        id: id,
        cnpj: cnpj,
        corporate_name: corporate_name,
        fantasy_name: fantasy_name,
        responsible: responsible,
        email: email,
        telephone: telephone,
        type: type,
        min_points_rescue: min_points_rescue,
        // moderator_points: moderator_points
      }
      if(campHandleEmptyFields(rest)) {
        return isRequiredField.value = true
      }
      if(campHandleEveryoneIsTrue(isInvalidField.value)) {
        isInvalidFieldWarning.value = true
        return isRequiredField.value = true
      }
      if(data.remittance_email?.length && !(data.weekday?.length)) {
        return isRequiredField.value = true
      }
      if(modelDaysToExpiry.value.checked && !days_to_expiry) {
        return isRequiredField.value = true
      }
      if(modelDaysToExpiryStock.value.checked && !days_to_expiry_stock) {
        return isRequiredField.value = true
      }
      loaderStore.open()
      try {
        const { data } = queryObject.value
        if(data) {
          const { telephone, cnpj, weekday, remittance_email, ...rest } = data
          const putData = {
            ...rest,
            telephone: campHandleBrPhoneNumber(telephone, "unformatted"),
            cnpj: campHandleCNPJ(cnpj, "unformatted"),
            front_card_image: imgCard.value.imgLogo,
            back_card_image: imgCard.value.imgBack,
            userCompany: userCompanyList.value,
            weekday: remittance_email?.length ? weekday : null,
            remittance_email: remittance_email?.length ? ([ ...new Set(remittance_email.split(", ")) ]).join(", ") : null
          }
          const result: ICompanyResponse = await axios.put(`/api/putCompany?id_company=${data.id}`, putData)
          showTimeAlert("Atualizado com sucesso!")
          return router.push("/companhias")
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      }
    }

    function handleRemoveImgLogo() {
      imgCard.value.imgLogo = null
      imgCard.value.imgNameLogo = ""
    }

    function handleRemoveImgBack() {
      imgCard.value.imgBack = null
      imgCard.value.imgNameBack = ""
    }

    /** Life cycles */
    onMounted(async () => handleCompanyView())

    return {
      queryObject,
      isRequiredField,
      isInvalidField,
      isInvalidFieldWarning,
      onSubmitForm,
      handleAnonymizeName,
      setImageCroped,
      imgCard,
      getImgLogo,
      auxModal,
      openModal,
      proportion,
      getImgBack,
      modatlCroppedLogo,
      openModalBack,
      auxModalBack,
      selectUserCompany,
      userList,
      getUserView,
      userCompanyList,
      handleDeleteUserCompany,
      isLoading,
      comeBackFunction,
      daysOfWeek,
      modelDaysToExpiry,
      modelDaysToExpiryStock,
      handleRemoveImgLogo,
      handleRemoveImgBack,
    }
  }
})
