import axios, { AxiosError } from "axios";
import useAlert from "@/composables/Alert";

const { showTimeAlert } = useAlert()

export async function ApiGet(url, options = {}) {
        const response = await axios.get(url, {
            ...options
        })
        if(response instanceof AxiosError || response instanceof Error) {
            showTimeAlert('Algo deu errado!', "error")
        }
        return response
}